




























































import ICrudClient from "@/lib/ICrudClient";
import { UserGroup } from "@/data/models/UserGroups";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { getPermissions } from "@/lib/permissions";

@Component({
  components: {
    FormContainer,
    Loader,
  },
})
export default class UserGroupForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<UserGroup>;

  @Prop()
  public crud!: ICrudClient<UserGroup>;

  private item: any = null;
  private permissionObject: any = {};

  @Watch("item")
  onItemChanged() {
    if (this.item) {
      this.permissionObject = this.item.permissions
        ? getPermissions(JSON.parse(this.item.permissions))
        : getPermissions({});
    }
  }

  mounted() {
    if (this.id === "new") {
      this.item = {
        name: "",
      };
    }
  }

  transform() {
    this.item.permissions = JSON.stringify(this.permissionObject);
    return this.item;
  }
  toCapitalizedWords(name: string) {
    const words = name.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(this.capitalize).join(" ");
  }

  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }
}
